"use strict";

export const ARRAY = "array";
export const BIT = "bit";
export const BITS = "bits";
export const BYTE = "byte";
export const BYTES = "bytes";
export const EMPTY = "";
export const EXPONENT = "exponent";
export const FUNCTION = "function";
export const IEC = "iec";
export const INVALID_NUMBER = "Invalid number";
export const INVALID_ROUND = "Invalid rounding method";
export const JEDEC = "jedec";
export const OBJECT = "object";
export const PERIOD = ".";
export const ROUND = "round";
export const S = "s";
export const SI_KBIT = "kbit";
export const SI_KBYTE = "kB";
export const SPACE = " ";
export const STRING = "string";
export const ZERO = "0";
